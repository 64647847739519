const COVERED_ACTIVITIES = [
    "horse riding",
    "paragliding",
    "surfing",
    "chess playing",
    "MTB riding",
    "walking",
    "lead climbing",
    "boxing",
    "karate chopping",
    "deep sea diving",
    "running",
    "skiing",
    "snowboarding",
    "snowmobiling",
    "e-biking",
    "skateboarding",
    "pogo sticking",
    "road bikes",
    "mountain bikes",
    "cycling",
    "soccer",
    "basketball",
    "tennis",
    "baseball",
    "golf",
    "volleyball",
    "badminton",
    "swimming",
    "professional sports",
    "table tennis",
    "ice skating",
    "roller skating",
    "cricket",
    "rugby",
    "pool",
    "darts",
    "football",
    "bowling",
    "ice hockey",
    "karate",
    "horse racing",
    "archery",
    "fishing",
    "gymnastics",
    "figure skating",
    "rock climbing",
    "sumo wrestling",
    "taekwondo",
    "fencing",
    "water skiing",
    "jet skiing",
    "weight lifting",
    "scuba diving",
    "judo",
    "wind surfing",
    "kickboxing",
    "sky diving",
    "hang gliding",
    "bungee jumping",
    "Abseiling",
    "Adventure park",
    "Airsoft",
    "All-terrain vehicle riding",
    "Amusement park",
    "Angling",
    "Aviation",
    "Backpacking",
    "BASE jumping",
    "Benchmarking (geolocating)",
    "Birdwatching",
    "Camping",
    "Canoeing",
    "Canyoning",
    "Caving",
    "Clam digging",
    "Coasteering",
    "Cold-weather biking",
    "Corn maze",
    "Cross-country skiing",
    "Disc golf",
    "Dog park",
    "Driving",
    "Fitness trail",
    "Fly fishing",
    "Gardening",
    "Geocaching",
    "Gliding",
    "Grilling",
    "Hiking",
    "Horseback riding",
    "Hot air ballooning",
    "Hunting",
    "Ice climbing",
    "Ice fishing",
    "Jetskiing",
    "Kayaking",
    "Letterboxing",
    "Metal detecting",
    "Mountain biking",
    "Mountain climbing",
    "Mountaineering",
    "Mushroom hunting",
    "Nordic walking",
    "Off-roading",
    "Orienteering",
    "Outdoor fitness",
    "Outdoor gym",
    "Paintball",
    "Parasailing",
    "Outdoor party",
    "Photography",
    "Picnic",
    "Plogging",
    "Paramotoring",
    "Rafting",
    "Rappelling",
    "Safari park",
    "Safari",
    "Sandboarding",
    "Seatrekking",
    "Sightseeing",
    "Sport fishing",
    "Skydiving",
    "Skyrunning",
    "Sledding",
    "Snorkeling",
    "Snowshoeing",
    "Standup paddleboarding",
    "Sunbathing",
    "Tombstoning",
    "Tourism",
    "Tree climbing",
    "Trekking",
    "Urban exploration",
    "Water sports",
    "Waterskiing",
    "Windsurfing",
    "Wingsuit flying",
    "Winter swimming",
    "Zip line",
    "Touch Footy",
    "Oztag",
    "blobbing",
    "zorbing",
    "mountain boarding",
    "BMX",
    "Dancesport",
    "Dandi Biyo",
    "Danish Longball",
    "Dartchery",
    "Daur Hockey",
    "Deadlifting",
    "Deaf Basketball",
    "Decathlon",
    "Demolition Derby",
    "Digor",
    "Disc Dog",
    "Disc Sports",
    "Discus",
    "Diving",
    "Dodgeball",
    "Dog Agility",
    "Dog Racing",
    "Dog Sledding",
    "Dog Sports",
    "Downhill Skiing",
    "Downhill Mountain Biking",
    "Drag boat racing",
    "Drag racing",
    "Dragon Boat Racing",
    "Dressage",
    "Drifting",
    "Drone Racing",
    "Duathlon",
    "Duckpin bowling",
    "3d Archery",
    "3x3 Basketball",
    "3x3 Ice Hockey",
    "7-a-side football",
    "Acrobatic Gymnastics",
    "Acroski",
    "Adventure Racing",
    "Aerials",
    "Aerobatics",
    "Aesthetic Group Gymnastics",
    "Aerobic Gymnastics",
    "Aeromodeling",
    "Aeronautical Pentathlon",
    "AFL Wheelchair",
    "AFLX",
    "Aggressive Inline Skating",
    "Aikido",
    "Air Badminton",
    "Air Hockey",
    "Air Racing",
    "All-Terrain Boarding",
    "Alpine Skiing",
    "Alpinism",
    "American Football",
    "American Handball",
    "Amputee Football",
    "Aquabike",
    "Aquajogging",
    "Aquathlon (1)",
    "Aquathlon (2)",
    "Arena Football",
    "Arena Polo",
    "Arena Rugby",
    "Arm Wrestling",
    "Arnis",
    "Artistic Billiards",
    "Artistic Cycling",
    "Artistic Gymnastics",
    "Artistic Pool",
    "Artistic Roller Skating",
    "Association Croquet",
    "Association Football",
    "Athletics",
    "Australian Football",
    "Australian Handball",
    "Auto Racing",
    "Autocross",
    "Autograss",
    "Autospeedway",
    "Axe Throwing",
    "Backstroke",
    "Balance Beam",
    "Balkline",
    "Ball Badminton",
    "Ball Hockey",
    "Ballooning",
    "Bandy",
    "Banger Racing",
    "Banzai Skydiving",
    "Barefoot Skiing",
    "Bare-knuckle boxing",
    "Barrel Racing",
    "Baseball5",
    "Basque Pelota",
    "Basse",
    "Bat-and-Trap",
    "Baton Twirling",
    "Bavarian Curling",
    "Beach Basketball",
    "Beach Flags",
    "Beach Golf",
    "Beach Handball",
    "Beach Netball",
    "Beach Polo",
    "Beach Rugby",
    "Beach Sambo",
    "Beach Soccer",
    "Beach Sprint Rowing",
    "Beach Tennis",
    "Beach Volleyball",
    "Beach Water Polo",
    "Beach Woodball",
    "Beach Wrestling",
    "Beeni Wrestling",
    "Behcup",
    "Beikou Tarkbei (also called Daur Hockey)",
    "Belt Wrestling",
    "Benchpress",
    "Benchrest Shooting",
    "Biathle",
    "Biathlon",
    "Bicycle Motocross (BMX)",
    "Bicycle Polo",
    "Big Wave Surfing",
    "Bikejoring",
    "Billiards",
    "Biribol",
    "Blind Cricket",
    "Blind Football",
    "Boardercross",
    "Bobsleigh",
    "Bocce",
    "Boccia",
    "Bodyboarding",
    "Bodybuilding",
    "Bodyflight",
    "Bolas Criollas",
    "Boli Khela",
    "Boomerang Throwing",
    "Borden Ball",
    "Bossaball",
    "Boßeln",
    "Bouldering",
    "Boules",
    "Bowls",
    "Box lacrosse",
    "Brännboll",
    "Brazilian Jiu-jitsu",
    "Breakdancing",
    "Breaststroke",
    "Bridge",
    "British Baseball",
    "Broomball",
    "Bull Fighting",
    "Bull Riding",
    "Bunnock",
    "Butterfly stroke",
    "Buzkashi",
    "Caber Toss",
    "Cageball",
    "Calisthenics",
    "Calva",
    "Camel Racing",
    "Cammag",
    "Camogie",
    "Campdrafting",
    "Canadian Football",
    "Candlepin Bowling",
    "Caneball",
    "Canicross",
    "Canne de Combat",
    "Canoe Freestyle",
    "Canoe Marathon",
    "Canoe Polo",
    "Canoe Slalom",
    "Canoe Sprint",
    "Capoeira",
    "Canopy Piloting",
    "Capture The Flag",
    "Car Ice Racing",
    "Carriage Driving",
    "Carom Billiards",
    "Casting",
    "Catchball",
    "Cestoball",
    "Chase Tag",
    "Cheerleading",
    "Chess",
    "Chess boxing",
    "Chilean Rodeo",
    "Chinese Handball",
    "Chinlone",
    "Chuckwagon Racing",
    "Circle Rules Football",
    "Clay Pigeon Shooting",
    "Clean and Jerk",
    "Clout Archery",
    "Club Throw",
    "Coastal (Offshore) Rowing",
    "Collegiate Wrestling",
    "Combined Driving",
    "Competitive Eating",
    "Corkball",
    "Cornhole",
    "Cowboy Action Shooting",
    "Cowboy Mounted Shooting",
    "Cowboy Polo",
    "Court Tennis",
    "Crab Soccer or Crab Football",
    "Crazy Golf",
    "Croquet",
    "Crossbow Archery",
    "Cross-Country Equestrian",
    "Cross-Country Mountain Biking",
    "Cross-Country Rally",
    "Cross-Country Running",
    "Crossnet",
    "Cross Triathlon",
    "Crossfit",
    "Crossminton",
    "Cue Sports (Billiards)",
    "Cup Stacking",
    "Curling",
    "Cushion Caroms",
    "Cutting",
    "Cycle Ball",
    "Cycle Polo",
    "Cycle Speedway",
    "Cycling Time Trial",
    "Cyclo-Cross",
    "Czech Handball",
    "Dumog",
    "Ecuavoley",
    "Eight-Ball",
    "Ekiden",
    "Elephant Polo",
    "Endurance Racing",
    "Endurance Riding",
    "Endurance Running",
    "English Billiards",
    "Equestrian",
    "Equestrian Vaulting",
    "e-Sports",
    "Eton Fives",
    "European (Team) Handball",
    "Eventing",
    "Expedition racing",
    "Extreme Canoe Slalom",
    "Fast Pitch Softball",
    "Fast5",
    "Fell Running",
    "Field Archery",
    "Field Handball",
    "Field Hockey",
    "Field Lacrosse",
    "Field Target",
    "Fierljeppen",
    "Finnish Skittles",
    "Finswimming",
    "Fistball",
    "Fisticuffs",
    "Five-a-Side Football",
    "Five-pin bowling",
    "Five-Pin Billiards",
    "Fives",
    "Flag Football",
    "Flight Archery",
    "Floor (Gymnastics)",
    "Floor Hockey",
    "Floorball",
    "Flyboarding",
    "Footbag",
    "Footbag Net",
    "Football Tennis",
    "Footgolf",
    "Footpool",
    "Footvolley",
    "Formula Racing",
    "Four Square",
    "Four-Ball",
    "Frame Running",
    "Freediving",
    "Freerunning",
    "Freestyle BMX",
    "Freestyle Flying Disc",
    "Freestyle Footbag",
    "Freestyle Football",
    "Freestyle Motocross",
    "Freestyle Skateboarding",
    "Freestyle Skiing",
    "Freestyle Slalom Skating",
    "Freestyle Snowboarding",
    "Freestyle Swimming",
    "Freestyle Wrestling",
    "Fricket",
    "Frisian Handball",
    "Frontenis",
    "Fullbore target rifle",
    "Futsal",
    "Fuzzball",
    "Gaelic Football",
    "Gaelic Handball",
    "Gateball",
    "Giant Slalom",
    "Gilli-Danda",
    "Glima",
    "Goalball",
    "Golf Croquet",
    "Gorodki",
    "Grass Skiing",
    "Greco-Roman Wrestling",
    "Greyhound Racing",
    "Gridiron Football",
    "Guts",
    "Gymkhana",
    "Half-Pipe Skiing",
    "Hammer throw",
    "Handball",
    "Hardball Squash",
    "Hare Coursing",
    "Headis",
    "Heptathlon",
    "High Diving",
    "High Jump",
    "High Power Rifle",
    "Hillclimbing",
    "Hitball",
    "Hockey",
    "Hockey Fives",
    "Horizontal Bar",
    "Hornussen",
    "Horse Polo",
    "Horse Soccer",
    "Horseball",
    "Horseback Archery",
    "Horseshoes",
    "Hovercraft Racing",
    "Hurdles (Track and Field)",
    "Hurling",
    "Hydroplane Racing",
    "Ice Canoeing",
    "Ice Cross Downhill",
    "Ice Dancing",
    "Ice Racing",
    "Ice Stock Sport",
    "Ice Track Cycling",
    "Ice Yachting",
    "Icosathlon",
    "Indoor Cricket",
    "Indoor Field Hockey",
    "Indoor Netball",
    "Indoor Rowing",
    "Indoor Soccer",
    "Indoor Triathlon",
    "Inline Hockey",
    "Inline Skating",
    "Inline Speed Skating",
    "Intercrosse",
    "International Fronton",
    "International Game",
    "International Rules Football",
    "Irish Road Bowling",
    "Ironman Surflifesaving",
    "Jai Alai",
    "Javelin",
    "Jereed",
    "Jet Ski Racing",
    "Jetsprint",
    "Jeu Provençal (Boule Lyonnaise)",
    "Jianzi",
    "Joggling",
    "Jokgu",
    "Jugger",
    "Jiu-Jitsu / Jujutsu",
    "Jukskei",
    "Kabaddi",
    "Kaisa",
    "Kalaripayattu",
    "Kart Racing",
    "Kayak Football",
    "Kegel",
    "Kelly Pool",
    "Kemari",
    "Kettlebell Lifting",
    "Kho Kho",
    "Kickball",
    "Kilikiti",
    "Kin-Ball",
    "Ki-o-rahi",
    "Kite Fighting",
    "Kite Foil Racing",
    "Kite Landboarding",
    "Kite Boarding",
    "Kitesurfing",
    "Klootschieten",
    "Kneeboarding",
    "Knife Throwing",
    "Kolf",
    "Korfball",
    "Krachtbal",
    "Krav Maga",
    "Krolf",
    "Kronum",
    "Kubb",
    "Kung Fu",
    "Kurash",
    "Lacrosse",
    "Lagori",
    "Land Sailing",
    "Land Speed Records",
    "Land Windsurfing",
    "Lapta",
    "Laser Run",
    "Laser Tag",
    "Lawn Bowls",
    "Lawn Mower Racing",
    "Legends Car Racing",
    "Lelo Burti",
    "Lethwei",
    "Log Rolling",
    "Longboarding",
    "Long Drive",
    "Long Jump",
    "Longue Paume",
    "Luge",
    "Lumberjack",
    "Mallakhamba",
    "Marathon",
    "Marching Band",
    "Mas Wrestling",
    "Matkot",
    "Metallic Silhouette Shooting",
    "Metro Footy",
    "Middle Distance",
    "Military Pentathlon",
    "Mind Sports",
    "Minigolf or Miniature Golf",
    "Mini Rugby",
    "Mixed Martial Arts (MMA)",
    "Modern Pentathlon",
    "Mogul Skiing",
    "Molkky",
    "Mongolian Wrestling",
    "Moscow Broomball",
    "Mountain Unicycling",
    "Mountainboarding",
    "Mounted Orienteering",
    "Muay Thai",
    "Mud Bogging",
    "Muggle Quidditch",
    "Naginatajutsu",
    "Naval Pentathlon",
    "Netball",
    "Newcomb Ball",
    "Nine-a-side Footy",
    "Nine-Ball",
    "Nine-Man Football",
    "Nine Pin Bowling",
    "Ninjutsu",
    "Nordic Combined",
    "Nordic Skiing",
    "Northern Praying Mantis",
    "Novuss",
    "Obstacle Course Racing",
    "Ocean Rowing",
    "Offroad Boarding",
    "Off-Road Racing (excluding Motorcycle)",
    "Oil Wrestling",
    "Okinawan Kobudō",
    "One Day International (ODI)",
    "One-Pocket",
    "Open Water Swimming",
    "Outdoor Handball",
    "Outrigger Canoeing",
    "Paddle Tennis",
    "Paddleball (1 wall)",
    "Paddleball (4 wall)",
    "Paddleboarding",
    "Padel",
    "Palant",
    "Paleta Frontón",
    "Palla",
    "Pankration (Amateur)",
    "Para Badminton",
    "Parachuting",
    "Para-Climbing",
    "Para-Cycling",
    "Parallel Bars",
    "Paratriathlon",
    "Pärk (or Paerk)",
    "Parkour",
    "Park Skateboarding",
    "Patball",
    "Pato",
    "Pehlwani",
    "Pelota Mixteca",
    "Pencak Silat",
    "Pesäpallo",
    "Pétanque",
    "Peteca",
    "Pickleball",
    "Picigin",
    "Pigeon Racing",
    "Ping Pong",
    "Pitch and Putt",
    "Pitton",
    "Platform Tennis",
    "Playboating (also known as Canoe Freestyle)",
    "Pleasure Driving",
    "Pocket Billiards",
    "Poker",
    "Pole Climbing",
    "Pole Dancing",
    "Pole Vault",
    "Polo (Horse Polo)",
    "Polocrosse",
    "Pommel Horse",
    "Pond Hockey",
    "Popinjay",
    "Power Hockey",
    "Power Boat Racing",
    "Powerchair Football",
    "Powerlifting",
    "Practical shooting",
    "Pradal Serey",
    "Prizefighting",
    "Pushball",
    "Qianball",
    "Quadrathlon",
    "Quidditch",
    "Quoits",
    "Racerunning",
    "Race Walking",
    "Racketlon",
    "Racquetball",
    "Racquets / Rackets",
    "Raffa Bocce",
    "Rallycross",
    "Rally Racing (car)",
    "Rally Raid",
    "Real Tennis",
    "Rec Footy",
    "Reining",
    "Relay Running",
    "Relay Swimming",
    "Rhythmic Gymnastics",
    "Ringball",
    "Ringette",
    "Rings",
    "Rink Bandy",
    "Rink Hockey",
    "Rinkball",
    "Ritinis",
    "Road Bicycle Racing",
    "Road Bowling",
    "Road Tennis",
    "Road Skating",
    "Robot Combat",
    "Robot Sports",
    "Robot Soccer",
    "Rodeo",
    "Rogaining",
    "Roll Ball",
    "Roller Derby",
    "Roller Hockey",
    "Roller Skiing",
    "Roller Soccer",
    "Rope Climbing",
    "Rope Jumping (skipping)",
    "Roping",
    "Roque",
    "Rounders",
    "Roundnet",
    "Rowing",
    "Rugby Fives",
    "Rugby League",
    "Rugby League Nines",
    "Rugby League Sevens",
    "Rugby Sevens",
    "Rugby Tens",
    "Rugby Union",
    "Rugby X",
    "Russian Pyramid",
    "Sailing",
    "Sambo",
    "Samoa Rules",
    "Sandball",
    "Sandsurfing",
    "Sanshou (also called Sanda)",
    "Savate",
    "Sawing",
    "Schwingen",
    "Scootering",
    "Sculling Rowing",
    "Scurry Driving",
    "Seatball",
    "Segway Polo",
    "Sepak Takraw",
    "Shinty",
    "Shinty-Hurling",
    "Shooting Sports",
    "Shot put",
    "Showdown",
    "Show Jumping",
    "Shuffleboard",
    "Shuttlecock",
    "Sikaran",
    "Silambam",
    "Singlestick",
    "Sipa",
    "Sitting Volleyball",
    "Sixes Lacrosse",
    "Six-Man Football",
    "Skeet Shooting",
    "Skeleton",
    "Ski Archery",
    "Ski Ballet",
    "Ski Cross",
    "Ski Flying",
    "Ski Jumping",
    "Ski Mountaineering",
    "Ski Orienteering",
    "Skirmish",
    "Skiboarding",
    "Skibobbing",
    "Skijøring",
    "Skittles",
    "Skysurfing",
    "Slacklining",
    "Slalom Skiing",
    "Slamball",
    "Sledge Hockey",
    "Slingshot",
    "Slopestyle",
    "Slopestyle Skiing",
    "Slopestyle Snowboarding",
    "Slow-Pitch Softball",
    "Snatch",
    "Snocross (or snowcross)",
    "Snooker",
    "Snowbiking",
    "Snowboard Cross",
    "Snowboating",
    "Snow Golf",
    "Snowkiting",
    "Snowmobile Racing",
    "Snow Polo",
    "Snow Rugby",
    "Snow Skiing",
    "Snow Volleyball",
    "Soft Tennis",
    "Softball",
    "Softball Throw",
    "Sorro Wrestling",
    "Speed-Ball",
    "Speedball",
    "Speed Climbing",
    "Speedcubing",
    "Speed Golf",
    "Speedminton",
    "Speed Pool",
    "Speed Skating (Long Track)",
    "Speed Skating (Short Track)",
    "Speed Skiing",
    "Speedway (excluding motorcycle)",
    "Spongee",
    "Sport Acrobatics",
    "Sport Aerobics",
    "Sport Climbing",
    "Sport Diving",
    "Sport Kite",
    "Sport Stacking",
    "Sporting Clays",
    "Sports Car Racing",
    "Sports Table Football",
    "Sprint (Athletics)",
    "Sprint Car Racing",
    "Sprint Football",
    "Sprint Kayaking",
    "Squash",
    "Squash Tennis",
    "Squat",
    "Ssireum",
    "Steeplechase",
    "Stick-Fighting",
    "Stické Tennis",
    "Stock Car Racing",
    "Stone Lift or Carry",
    "Stoolball",
    "Straight pool",
    "Street Hockey",
    "Streetluge",
    "Street Skateboarding",
    "Strongman",
    "Super Giant Slalom (better known as Super-G)",
    "Supercross",
    "Surf Kayaking",
    "Surfboat Rowing",
    "Surf Lifesaving",
    "Swamp Football",
    "Sweep Rowing",
    "Swimrun",
    "Swish",
    "Synchronized Diving",
    "Synchronized Skating",
    "Synchronized Swimming",
    "T20 Cricket",
    "Table Football",
    "Table Tennis (also called Ping Pong)",
    "Tag Rugby",
    "Tamburello",
    "Target Archery",
    "Target Golf",
    "Target Shooting",
    "Tee-Ball",
    "Tchoukball",
    "Team Handball",
    "Team Penning",
    "Te Ano",
    "Tejo (Argentina)",
    "Tejo (Colombia)",
    "Telemark Skiing",
    "Tennikoit",
    "Tennis Polo",
    "Tenpin Bowling",
    "Tent Pegging",
    "Teqball",
    "Test Cricket",
    "Tetherball",
    "Tetradecathlon",
    "Tetrathlon",
    "Thoroughbred Racing",
    "Three-Cushion Billiards",
    "Throwball",
    "Torball",
    "Toros Coleados",
    "Touch Football",
    "Touch Rugby",
    "Touring Car Racing",
    "Tower Running",
    "Track Cycling",
    "Track & Field",
    "Tractor Pulling",
    "Trampolining",
    "Trap Shooting",
    "TREC",
    "Triathle",
    "Triathlon",
    "Tricking",
    "Triple jump",
    "Trugo",
    "Tug of War",
    "Tumbling",
    "Twenty20",
    "Ulama",
    "Ultimate",
    "Ultralight Aviation",
    "Ultramarathon",
    "Underwater Football",
    "Underwater Hockey",
    "Underwater Ice Hockey",
    "Underwater Orienteering",
    "Underwater Photography",
    "Underwater Rugby",
    "Underwater Target Shooting",
    "Underwater Wrestling",
    "Uneven Bars",
    "Unicycle Basketball",
    "Unicycle Handball",
    "Unicycle Hockey",
    "Unicycling Racing",
    "Unicycle Trials",
    "Unicycling",
    "Urban Golf",
    "Vajra-mushti",
    "Valencian Frontó",
    "Valencian Pilota",
    "Varpa",
    "Varzesh-e Bastani",
    "Vault",
    "Vert Skateboarding",
    "Vert Skating",
    "Vigoro",
    "Vintage Racing",
    "Vovinam",
    "Vx",
    "Wakeboarding",
    "Walking Football",
    "Walking Netball",
    "Walking Rugby",
    "Wallball",
    "Wallyball",
    "Washer Pitching",
    "Water Basketball",
    "Water Polo",
    "Water Volleyball",
    "Weightlifting",
    "Weight Throw",
    "Western Pleasure",
    "Wheelchair Basketball",
    "Wheelchair Curling",
    "Wheelchair Dancing",
    "Wheelchair Fencing",
    "Wheelchair Racing",
    "Wheelchair Rugby",
    "Wheelchair Rugby League",
    "Wheelchair Tennis",
    "Wildwater Canoe",
    "Whitewater Slalom (now known as Canoe Slalom )",
    "Wiffleball",
    "Winter Guard",
    "Winter Triathlon",
    "Women's lacrosse",
    "Woodball",
    "Wood Chopping",
    "Wrestling",
    "Wushu",
    "Xare",
    "Xingyiquan",
    "Yağlı Güreş",
    "Yak Polo",
    "Yoga Sport",
    "Yotsudama",
    "Yo-Yo",
    "Yubi Lakpi",
    "Yukigassen",
    "Zorb Football",
    "Zourkhaneh",
    "Zui Quan",
    "Aussie Rules",
    "motorcycling",
    "Motor bikes",
    "dirt bikes",
    "motorbikes",
    "motor cycles",
    "work (unless sport related)",
    "motorcross",
    "motor cross",
    "supermoto",
    "Motorcycle Speedway",
    "Rally Racing (motorcycle)",
    "Off-Road Racing (motorcycle)",
    "Enduro (motorcycle)",
    "Endurocross (motorcycle)",
    "Drunk",
    "Drinking",
    "War",
    "Alcohol",
];

const UNCOVERED_ACTIVITIES = [
    "motorcycling",
    "Motor bikes",
    "dirt bikes",
    "motorbikes",
    "motor cycles",
    "work (unless sport related)",
    "motorcross",
    "motor cross",
    "supermoto",
    "Motorcycle Speedway",
    "Rally Racing (motorcycle)",
    "Off-Road Racing (motorcycle)",
    "Enduro (motorcycle)",
    "Endurocross (motorcycle)",
    "Drunk",
    "Drinking",
    "War",
    "Alcohol",
];