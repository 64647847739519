document.addEventListener("DOMContentLoaded", (event) => {
    SegmentEvents.onAnalyticsReady(() => {
        ActivityFinder.initAutocompleteFinder();
    });
});

const ActivityFinder = (function(){
    let naBoxTimeouts = [];
    let activityKeyupSearchTimeouts = [];
    let activitySearched = '';

    function hasActivity(activityArr, value) {
        return activityArr.some(
            (activity) => activity.toLowerCase() == value.toLowerCase()
        );
    }

    function hideAutocompleteSuggestions(autoCompleteJS) {
        document.querySelector(
            `.${autoCompleteJS.resultsList.class}`
        ).style.display = "none";
    }

    function showAutocompleteSuggestions(autoCompleteJS) {
        document.querySelector(
            `.${autoCompleteJS.resultsList.class}`
        ).style.display = "flex";
    }

    function setSelectedActivity(selectedActivity) {
        activitySearched = selectedActivity;
        document
            .querySelectorAll('[activity-result="result-name-target"]')
            .forEach((resultBox) => (resultBox.innerHTML = selectedActivity));
    }

    function showResultBoxYes() {
        hideResultBoxes();
        document.querySelector('[activity-result="result-box-yes"]').style.display =
            "block";
    }

    function showResultBoxNo() {
        hideResultBoxes();
        document.querySelector('[activity-result="result-box-no"]').style.display =
            "block";
    }

    function showResultBoxNa(debounce, autoCompleteJS) {
        hideResultBoxes();

        clearAllTimeouts();

        const resultTimeout = setTimeout(() => {
            if (
                document.querySelector(autoCompleteJS.selector).value.trim() != ""
                && document.querySelector('[activity-result="result-box-yes"]').style.display != "block"
                && document.querySelector('[activity-result="result-box-no"]').style.display != "block"
            ) {
                document.querySelector(
                    '[activity-result="result-box-na"]'
                ).style.display = "block";
            }
        }, debounce);

        naBoxTimeouts.push(resultTimeout);
    }

    function hideResultBoxes() {
        document
            .querySelectorAll(".activity_result_result-box")
            .forEach((box) => (box.style.display = "none"));
    }

    function activityInputAutofocus(autoCompleteJS) {
        document
            .querySelector(".activity_result_trigger")
            .addEventListener("click", () => {
                setTimeout(() => {
                    document.querySelector(autoCompleteJS.selector).focus();
                }, 500);
            });
    }

    function clearAllTimeouts() {
        naBoxTimeouts.forEach((timeout) => clearTimeout(timeout));

        naBoxTimeouts = [];
    }

    function clearAllActivitySearchTimeouts() {
        activityKeyupSearchTimeouts.forEach((timeout) => clearTimeout(timeout));

        activityKeyupSearchTimeouts = [];
    }

    function fireAnalyticsSearchedEventDebounce() {
        const delay = 1300;

        return (isCovered) => {
            clearAllActivitySearchTimeouts();

            const timeoutId = setTimeout(() => {
                if (activitySearched.length > 3) {
                    SegmentEvents.trackActivitySearched(activitySearched, isCovered);
                }
            }, delay);

            activityKeyupSearchTimeouts.push(timeoutId)
        };
    }

    function initAutocompleteFinder() {
        if (!document.getElementById('activity-within-result')) return;

        const autoCompleteJS = new autoComplete({
            selector: `#activity-within-result`,
            data: {
                src: COVERED_ACTIVITIES,
            },
            placeHolder: "",
            resultsList: {
                tag: "ul",
                class: "activity_result_list-wrapper",
                noResults: true,
                maxResults: 20,
                tabSelect: true,
            },
            threshold: 3,
            resultItem: {
                tag: "li",
                class: "activity_result_list-item w-inline-block",
                element: (item, data) => {
                    item.innerHTML = `
                        <div activity-result="result-list-item-name">${data.match}</div>
                    `;
                },
                highlight: false,
            },
            events: {
                input: {
                    focus() {
                        if (autoCompleteJS.input.value.length) autoCompleteJS.start();
                    },
                    keyup(event) {
                        const value = event.target.value.trim();
                        const feedback = autoCompleteJS.feedback;

                        if (!feedback || (feedback && feedback.results.length == 0) || value == "") {
                            hideAutocompleteSuggestions(autoCompleteJS);
                            hideResultBoxes();
                        } else {
                            showAutocompleteSuggestions(autoCompleteJS);
                        }

                        setSelectedActivity(value);

                        let isCovered = "No";
                        if (hasActivity(UNCOVERED_ACTIVITIES, value)) {
                            showResultBoxNo();
                        } else if (hasActivity(COVERED_ACTIVITIES, value)) {
                            showResultBoxYes();
                            isCovered = "Yes";
                        } else if (feedback && feedback.results.length == 0 && value != "") {
                            showResultBoxNa(1, autoCompleteJS);
                        } else if (value !== "") {
                            showResultBoxNa(2000, autoCompleteJS);
                        }

                        if (event.key === "Enter") {
                            hideAutocompleteSuggestions(autoCompleteJS);
                        }

                        fireAnalyticsSearchedEventDebounce()(isCovered);
                    },
                    blur() {
                        hideAutocompleteSuggestions(autoCompleteJS);
                    },
                    selection(event) {
                        clearAllActivitySearchTimeouts();
                        
                        const feedback = event.detail;
                        autoCompleteJS.input.blur();

                        const selection = feedback.selection.value;

                        autoCompleteJS.input.value = selection;

                        document.querySelector(
                            `.${autoCompleteJS.resultsList.class}`
                        ).style.display = "none";

                        setSelectedActivity(selection);
                        
                        let isCovered = "No";
                        if (hasActivity(UNCOVERED_ACTIVITIES, selection)) {
                            showResultBoxNo();
                        } else {
                            showResultBoxYes();
                            isCovered = "Yes";
                        }
                        
                        SegmentEvents.trackActivitySearched(selection, isCovered);
                    },
                },
            },
        });

        hideAutocompleteSuggestions(autoCompleteJS);
        activityInputAutofocus(autoCompleteJS);
    }

    return {
        initAutocompleteFinder,
    }
})();